import React, {useContext} from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { AppContext } from "../context/AppContext";
import { ModalContext } from "../context/ModalContext";
import dayjs from 'dayjs'
import dropArrow from '../assets/drop_arrow.svg'

const Wrapper = styled.div`
    width: 100%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
`
const OrderHeader = styled.div`
    height: 40px;
    width: 100%;
    background: #F7F7F7;
    font-family: Inter, system-ui;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    padding: 13px 0px 14px 16px;
    margin-top: 8px;
    color: #595D65
`

const OrderBody = styled.h3`
    display: flex;
    width: 100%;
    height: 50px;
    align-content: top;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-family: Inter, system-ui;
    font-size: 14px;
    font-weight: normal;
    padding: 8px 16px 0px 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #FFFFFF;
`

const OrderFooter = styled.div`
    width: 100%;
    text-align: center;
    padding-bottom: 12px;
    background-color: #FFFFFF;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

const Box = styled.div`
    height 50px;
    width: 49%;
    height 30px;
    padding: 2px 0px 2px 0px;
`
const Item = styled.div`
    color: #000000;
    font-size: 13px;
    padding: 2px 0px 2px 0px;
`

const OrderDate = styled(Item)`
    text-align: left;
`
const OrderTotal = styled(Item)`
    color: #002A73;
    text-align: right;
`

const StoreInfo = (props) => {
    const { t } = useTranslation();
    const { value: appState } = useContext(AppContext);
    const { setValue: setModalState } = useContext(ModalContext);

    const handleOrderClick = () => {
        setModalState(state => ({
            ...state ,
            showOverlay: true,
            showOrderModal: true,
            showScheduleModal: false
        }));
    }

    const getOrderTotal = (total) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);
    }

    const orderDate = dayjs(appState?.order?.orderDate);
    const orderDateFormatted = (orderDate ? orderDate.format('MMMM D, YYYY') : '');

    return (
      <Wrapper onClick={handleOrderClick}>
        <OrderHeader>{t('common.text.order', { defaultValue: 'ORDER'})} #{appState?.order?.orderNumber || ''}</OrderHeader>
        <OrderBody>
            <Box>
                <OrderDate>{ orderDateFormatted }</OrderDate>
            </Box>
            <Box>
                <OrderTotal>{getOrderTotal(appState?.order?.orderTotal || 0)}</OrderTotal>
            </Box>
        </OrderBody>
        <OrderFooter>
            <img src={dropArrow} alt='Show Order Information' />
        </OrderFooter>
      </Wrapper>
    );
}

export default StoreInfo;
