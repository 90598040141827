import React, {useState, createContext, useMemo} from 'react';
import config from '../config/vars';

const ModalContext = createContext([{}, () => {}]);

const ModalContextProvider = (props) => {
    const [value, setValue] = useState(config.modalDefaults);
    const modalProviderValue = useMemo(() => ({ value, setValue }), [value, setValue]);

    return (
        <ModalContext.Provider value={modalProviderValue}>
            {props.children}
        </ModalContext.Provider>
    );
}

export { ModalContext, ModalContextProvider }
