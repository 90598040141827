import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    box-sizing: border-box;
    margin-top: 12px;
    margin-bottom: 12px;
    float: left;
    display: inline-block;
    width: fit-content;
    
    input[type=radio] { 
        margin-left: 0px; 
        margin-right:5px; 
    }
    input[type='radio'], label{   
        vertical-align:top;
    }
    
    label {
  
      font-family: Inter, system-ui;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 125%;
    }
`

const OrderType = (props) => {
  return (
    <Wrapper onClick={() => {props.fn(props.name); }}>
      <input
       type="radio"
       name="orderTypes"
       value={props.name}
       checked={props.selected}
       defaultChecked={() => console.log(`Selected ${props.name}`)}
      />
      <label htmlFor={props.name}>
        {props.description}
      </label>
    </Wrapper>
  )
}

export default OrderType