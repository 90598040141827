import React from 'react';
import vars from '../config/vars';

const QAInfoHeader = () => {
  return (
    <div data-testid="qainfoheader">
      |||NOT SHOWN IN PROD||| <strong>Environment:</strong>{' '}
      {vars.environmentType}, <strong>Impl Sandbox Branch:</strong>{' '}
      {vars.sandboxBranch}, <strong>Curbside UI Branch:</strong>{' '}
      {vars.bitbucketBranch} |||NOT SHOWN IN PROD|||
    </div>
  );
};

export default QAInfoHeader;
