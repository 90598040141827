import React, {useContext, useState} from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import LinkButton from '../components/LinkButton'
import Title from '../components/Title'
import Text from '../components/Text'
import StoreInfo from '../components/StoreInfo'
import OrderInfo from '../components/OrderInfo'
import useOrderCheck from '../hooks/useOrderCheck'
import {ModalContext} from "../context/ModalContext";
import {AppContext} from "../context/AppContext";
import ErrorDetails from "../components/ErrorDetails";
import OrderType from "../components/Form/OrderType";
import {useHistory} from "react-router-dom";

const Container = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }`

const orderTypes = [
    {name: 'bopis', description: 'Pick up in store'},
    {name: 'curbside', description: 'Pick up curbside'},
];

const OnMyWay = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { value: appState, setValue: setAppState } = useContext(AppContext);
    const { setValue: setModalState } = useContext( ModalContext );
    const [orderType, setOrderType] = useState('bopis');
    useOrderCheck();

    if(appState?.hasError){
        return <ErrorDetails />
    } else {
        return (
            <Container>
                <Title text={t('onway.text.header', { defaultValue: 'Your curbside order is ready!'})} />
                <Text text={t('onway.text.let_us_know', { defaultValue: 'Please let us know when you are on your way then enter your vehicle details.'})} />
                <Text text={t('onway.text.bring_id', { defaultValue: 'Reminder, please bring your ID when picking up your order.'})} />
                {orderTypes.map((type, i) =>
                    <OrderType
                        key={i}
                        name={type.name}
                        description={type.description}
                        selected={type.name === orderType}
                        fn={setOrderType}
                    />
                )}
                <StoreInfo />
                <OrderInfo />
                <LinkButton
                    text={t('onway.button.primary', { defaultValue: `I'm On My Way` })}
                    fn={()=> {
                        setAppState(appState => ({
                            ...appState,
                            order: {
                                ...appState.order,
                                type: orderType
                            }
                        }))
                        if (orderType === 'bopis') {
                            history.push('/bopispickup')
                        } else {
                            setModalState(state => ({
                                ...state,
                                showOverlay: true,
                                showVehicleDetailsModal: true
                            }));
                        }
                    }
                    }
                />
            </Container>
        )
    }

}

export default OnMyWay