import React, {useContext, useState} from 'react';
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import config from '../config/vars';

import styled from 'styled-components'
import Overlay from "./Overlay";
import TapGuard from "./TapGuard";
import Modal from "./Modal";
import close from '../assets/close.svg'
import Order from "./Order";
import StoreSchedule from "./StoreSchedule";
import StoreAddress from "./StoreAddress";
import VehicleDetails from "../components/VehicleDetails";
import LinkButtonContainer from "./LinkButtonContainer"

import {ThemeContext} from '../context/ThemeContext';
import {ModalContext} from '../context/ModalContext';
import {AppContext} from "../context/AppContext";

import * as platformApi from '../handlers/platform-api';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    min-width: 100%
    min-height: 100%;
    height: 100%;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
    flex:1`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    background-color: rgba(249, 249, 249, 0.94);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    height: 50px;
    padding: 16px;
    span{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
    }
    img{
        background-color: rgba(118, 118, 128, 0.12);
        color: #83868C;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        padding: 7px
    }
`

const ModalCard = styled.div`

`

const IconContainer = styled.a`
  display: inline-flex;
  background-color: $background;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: darken($background, 5%);
  }
`

const ConfirmContainer = styled.div`
  height: 80px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  padding: 24px 16px;
`

const ViewContainer = (props) => {
    const { t } = useTranslation();
    const {value: appState, setValue: setAppState} = useContext(AppContext);
    const {value: themeState} = useContext(ThemeContext);
    const {value: modalState, setValue: setModalState} = useContext(ModalContext);
    const [isLoadingPrimary, setIsLoadingPrimary] = useState(false);
    const brand = appState?.order?.b || sessionStorage.getItem('brand');

    const history = useHistory();

    const handleMissingDetailsClick = (btnClicked) => {
        if (btnClicked === 'no') {
            setModalState(modalState => ({
                ...modalState,
                showOverlay: true,
                showVehicleDetailsModal: true,
                showMissingDetails: false
            }))
        } else {
            setModalState(modalState => ({
                ...modalState,
                ...config.modalDefaults
            }))
        }
    }

    const handleComeBackLaterClick = (btnClicked) => {
        if (btnClicked === 'no') {
            setModalState(modalState => ({
                ...modalState,
                ...config.modalDefaults
            }))
        } else {
            let payload = {
                brand,
                order: {
                    orderNumber: appState?.order?.orderNumber || '',
                    orderKey: appState?.order?.orderKey || '',
                    orderType: appState?.order?.type || ''
                }
            };

            setIsLoadingPrimary(true);
            setModalState(modalState => ({...modalState, showTapGuard: true}))
            platformApi.customerCancelled(payload)
                .then(order => {
                    setAppState(appState => ({
                        ...appState,
                        order: {...order}
                    }));
                    history.go(0);  // this should send back to onmyway view
                })
                .catch(e => {
                    setAppState(appState => ({
                        ...appState,
                        hasError: true,
                        pending: false
                    }));
                })
                .finally(() => {
                    setIsLoadingPrimary(false);
                    setModalState(modalState => ({...modalState, ...config.modalDefaults}));
                })
        }
    }

    return (
        <Wrapper>
            {props.children}
            <Overlay in={modalState.showOverlay}/>
            <TapGuard in={modalState.showTapGuard}/>
            <Modal in={modalState.showOrderModal}>
                <ModalCard>
                    <ModalHeader>
                        <span>{t('viewcontainer.modal.orderdetails.text.header', { defaultValue: 'Order Details'})}</span>
                        <IconContainer>
                            <img src={close} alt="" onClick={() => setModalState(value => ({
                                ...modalState,
                                showOverlay: false,
                                showOrderModal: false
                            }))}/>
                        </IconContainer>
                    </ModalHeader>
                    <Order/>
                </ModalCard>
            </Modal>
            <Modal in={modalState.showScheduleModal}>
                <ModalCard>
                    <ModalHeader>
                        <span>{t('viewcontainer.modal.storehours.text.header', { defaultValue: 'Store Hours'})}</span>
                        <IconContainer>
                            <img src={close} alt="" onClick={() => setModalState(modalState => ({
                                ...modalState,
                                showOverlay: false,
                                showScheduleModal: false
                            }))}/>
                        </IconContainer>
                    </ModalHeader>
                    <StoreAddress />
                    <StoreSchedule />
                </ModalCard>
            </Modal>
            <Modal in={modalState.showVehicleDetailsModal}>
                <ModalCard>
                    <ModalHeader>
                        <span>{t('viewcontainer.modal.vehicledetails.text.header', { defaultValue: 'Vehicle Details'})}</span>
                        <IconContainer>
                            <img src={close} alt="" onClick={() => setModalState(modalState => ({
                                ...modalState,
                                ...config.modalDefaults
                            }))}/>
                        </IconContainer>
                    </ModalHeader>
                    <VehicleDetails/>
                </ModalCard>
            </Modal>
            <Modal in={modalState.showMissingDetails}>
                <ModalCard>
                    <ModalHeader>
                        <span>Vehicle Details</span>
                        <IconContainer>
                            <img src={close} alt="" onClick={() => setModalState(modalState => ({
                                ...modalState,
                                ...config.modalDefaults
                            }))}/>
                        </IconContainer>
                    </ModalHeader>
                    <ConfirmContainer>
                        You have not selected your vehicle details. Do you want to come back later?
                    </ConfirmContainer>
                    <LinkButtonContainer
                        primaryText="Yes"
                        primaryAction={() => handleMissingDetailsClick('yes')}
                        primaryColor="#FFF"
                        primaryBGColor={themeState.primary}
                        primaryBorder={themeState.primary}
                        secondaryText="No"
                        secondaryAction={() => handleMissingDetailsClick('no')}
                        secondaryColor="var(--grey)"
                        secondaryBGColor="#FFF"
                        secondaryBorder="1px solid #DDDCDE"/>
                </ModalCard>
            </Modal>
            <Modal in={modalState.showComeBackLater}>
                <ModalCard>
                    <ModalHeader>
                        <span>Come Back Later</span>
                        <IconContainer>
                            <img src={close} alt="" onClick={() => setModalState(modalState => ({
                                ...modalState,
                                ...config.modalDefaults
                            }))}/>
                        </IconContainer>
                    </ModalHeader>
                    <ConfirmContainer>
                        Are you sure you want to come back later?
                    </ConfirmContainer>
                    <LinkButtonContainer
                        primaryText="Yes"
                        isLoadingPrimary={isLoadingPrimary}
                        primaryAction={() => handleComeBackLaterClick('yes')}
                        primaryColor="#FFF"
                        primaryBGColor={themeState.primary}
                        primaryBorder={themeState.primary}
                        secondaryText="No"
                        secondaryAction={() => handleComeBackLaterClick('no')}
                        secondaryColor="var(--grey)"
                        secondaryBGColor="#FFF"
                        secondaryBorder="1px solid #DDDCDE"/>
                </ModalCard>
            </Modal>
        </Wrapper>)
}
export default ViewContainer