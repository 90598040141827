module.exports = {
  curbsideApi: process.env.REACT_APP_CURBSIDE_API,
  platformTokenApi: process.env.REACT_APP_PLATFORM_TOKEN_API,
  platformApiKey: process.env.REACT_APP_MAD_CLOUD_API_KEY,
  totpInterval: 1200,
  sentry: process.env.REACT_APP_SENTRY_DSN,
  modalDefaults: {
    showOverlay: false,
    showTapGuard: false,
    showOrderModal: false,
    showScheduleModal: false,
    showVehicleDetailsModal: false,
    showMissingDetails: false,
    showComeBackLater: false
  },
  environmentType: process.env.REACT_APP_ENV_TYPE,
  bitbucketBranch: process.env.REACT_APP_BITBUCKET_BRANCH,
  appVersion: process.env.REACT_APP_VERSION,
  sandboxBranch: process.env.REACT_APP_MM_IMPL_SANDBOX,
};
