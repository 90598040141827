//Core
import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
    font-family: Inter, system-ui;
    font-size: 14px;
    line-height: 125%;
    text-align: left;
    font-weight: normal;
    color: var(--concierge-dark);
    margin: 8px 0px 8px 0px`

const Text = (props) => {
  return (
    <Wrapper dangerouslySetInnerHTML={{ __html: props.text}} />
  )
}

export default Text
