import React, { useContext } from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import Title from '../components/Title'
import Text from '../components/Text'
import StoreInfo from '../components/StoreInfo'
import OrderInfo from '../components/OrderInfo'
import useOrderCheck from '../hooks/useOrderCheck'
import ErrorDetails from "../components/ErrorDetails";
import {AppContext} from "../context/AppContext";
import {ModalContext} from "../context/ModalContext";

const Container = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }`
const CancelText = styled.div`
    margin: 10px 0px 10px 0px;
`
const CancelLink = styled.a`
    margin: 0px 0px 0px 0px;
    text-decoration: underline; 
`

const BopisPickup = (props) => {
    const { t } = useTranslation();
    const { value: appState } = useContext(AppContext);
    const { setValue: setModalState } = useContext( ModalContext );
    useOrderCheck();

    const handleComeBackLaterLink = () => {
        setModalState(modalState => ({
            ...modalState ,
            showOverlay: true,
            showComeBackLater: true,
            showVehicleDetailsModal: false
        }))
    }

    if(appState?.hasError){
        return <ErrorDetails />
    } else {
        return (
            <Container>
                <Title text={t('bopispickup.text.header', { defaultValue: `We'll see you at the counter!`})} />
                <Text text={t('"bopispickup.text.bring_id": "",', { defaultValue: 'Please have your ID ready to show the associate'})} />
                <StoreInfo />
                <OrderInfo />
                <CancelText>
                    {t('imhere.text.cancel.pickup', { defaultValue: 'Need to cancel your arrival?'})}
                    <CancelLink onClick={handleComeBackLaterLink}>&nbsp;{t('imhere.link.cancel.pickup', { defaultValue: 'Let us know'})}</CancelLink>
                </CancelText>
            </Container>
        )
    }
}

export default BopisPickup