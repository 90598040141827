import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import Title from '../components/Title'
import OrderInfo from '../components/OrderInfo'

import LinkButton from "../components/LinkButton";
import useOrderCheck from "../hooks/useOrderCheck";
import {AppContext} from "../context/AppContext";

const Container = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Inter, system-ui;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    color: #595D65;
  }`

const PageTitleWrapper = styled.div`
    margin-bottom: 24px;
`
const SomeWrongWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 8px;
`

const SomeWrongText = styled.div`
    color: #595D65;
    font-size: 16px;
    line-height: 140%;
    align-self: center;
`

const ButtonWrapper = styled.div`
    width: 100%;
    margin-top: auto;
`

const VerifyCode = (props) => {
    useOrderCheck();
    const { t } = useTranslation();

    const {value: appState} = useContext(AppContext);

    const handleCallStoreClick = () => {
        const phone = appState?.order?.storeInfo?.phoneNumber || null;
        if(phone) {
            document.location.href = `tel:${phone}`;
        }
    }

    return (
        <Container>
            <PageTitleWrapper>
                <Title text={t('success.text.header', { defaultValue: 'This order has been picked up successfully.'})} />
            </PageTitleWrapper>
            <OrderInfo/>
            <SomeWrongWrapper>
                <SomeWrongText>{t('success.text.something.wrong', { defaultValue: 'Something wrong?'})}</SomeWrongText>
            </SomeWrongWrapper>
            <ButtonWrapper>
                <LinkButton
                    text={t('common.text.call.store', { defaultValue: 'Call the store'})}
                    fn={handleCallStoreClick}
                />
            </ButtonWrapper>
        </Container>
    )
}

export default VerifyCode