import React, {useContext} from 'react'
import { Transition } from 'react-transition-group';
import { ModalContext } from "../context/ModalContext";

const duration = 0;
const defaultStyle = {
  'backgroundColor': 'rgba(0, 0, 0, 0.55)',
  'top': 0,
  'bottom': 0,
  'left': 0,
  'right': 0,
  'position': 'fixed',
  'display': 'none',
  'zIndex': 5
}

const transitionStyles = {
  entering: { display: 'block' },
  entered: { display: 'block' },
  exiting: { display: 'none' },
  exited: { display: 'none' }
}

const Overlay = (props) => {
  const { setValue: setModalState } = useContext(ModalContext);

  const handleClick = () => {
    setModalState(state => ({
      ...state ,
      showOverlay: false,
      showOrderModal: false,
      showScheduleModal: false,
      showVehicleDetailsModal: false,
      showMissingDetails: false,
      showComeBackLater: false
    }));
  }

  return (
      <Transition in={props.in} timeout={duration}>
        {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }} onClick={handleClick}
            >
            </div>
        )}
      </Transition>
  )
}

export default Overlay