import React, {useContext} from 'react'
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Title from "./Title";
import Text from "./Text";
import OrderInfo from "./OrderInfo";
import Button from './LinkButton'
import styled from 'styled-components'

import {AppContext} from "../context/AppContext";
import {ThemeContext} from "../context/ThemeContext";

const Container = styled.div`
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Inter, system-ui;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    color: #595D65;
  }
`

const PageTitleWrapper = styled.div`
    margin-bottom: 24px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: auto;
  
  a {
    flex: 1 1 auto;
    margin: 8px;
    flex: 1;
  }
`

const ErrorDetails = () => {
    const { t } = useTranslation();
    const { value: appState } = useContext(AppContext);
    const {value: themeState} = useContext(ThemeContext);

    const order = appState?.order || null;
    const phone = order?.storeInfo?.phoneNumber || null;

    const handleCallStoreClick = () => {
        if(phone) {
            document.location.href = `tel:${phone}`;
        }
    }

    return (
        <Container>
            <PageTitleWrapper>
                <Title text={t('common.error.message.header', { defaultValue: 'We apologize but something went wrong with your request.'})} />
            </PageTitleWrapper>
            <Text text={t('common.error.message.text', { defaultValue: 'Please use the button below to show your order status or call the store.'})} />
            {!isEmpty(order) && <OrderInfo/>}
            <ButtonWrapper>
                <Button text="Call Store"
                        color="#FFF"
                        bgColor={themeState.primary}
                        fn={handleCallStoreClick}
                        border={themeState.primary}
                />
            </ButtonWrapper>

        </Container>
    )
}

export default ErrorDetails