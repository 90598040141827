import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components'
import ItemImage from './ItemImage'

const Wrapper = styled.div`
display: flex;
align-items: flex-start;
justify-content: space-between;
padding: 16px;
margin-bottom: 10px;

@media only screen and (max-width: 320px) {
  font-size: .8em;
}
`
const Details = styled.div`
    padding-left: .7em;
    padding-right: .7em;
    text-align: right;
`

const DetailLine = styled.div`
    text-align: left;
    font-size: 16px;
    line-height: 125%;
    font-style: normal;
`

const ProductName = styled(DetailLine)`
    word-wrap: break-word;
    font-weight: bold;
    font-size: 16px;
`

const ProductPrice = styled(DetailLine)`
    font-weight: normal;
    padding-bottom: 16px;
`

const ProductDetail = styled(DetailLine)`
    font-weight: normal;
    line-height: 17.5px;
    padding-bottom: 16px;
`

const ProductDetailLabel = styled.span`
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: var(--concierge-dark-gray);
`

const ProductDetailValue = styled.span`
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: #838895;
`

const ImageContainer = styled.div`
    border-radius: 5px;
    display:flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background: white;
    
    img{
        height: 100%;
    }
`

const OrderItem = (props) => {
    const {t} = useTranslation();

    /* Limit title lenght */
    const truncateString = (str, num) => {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }

    const getItemPrice = (itemPrice) => {
        return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(itemPrice);
    }

    return (
        <Wrapper>
            <ImageContainer>
                <ItemImage src={props.imageUrl} alt={truncateString(props.name, 10)}/>
            </ImageContainer>
            <Details>
                <ProductName>{truncateString(props?.name, 27)}</ProductName>
                <ProductPrice>{getItemPrice(props?.price || 0)}</ProductPrice>
                <ProductDetail>
                    <ProductDetailLabel>{t('common.text.quantity', { defaultValue: 'Quantity:'})}&nbsp;</ProductDetailLabel>
                    <ProductDetailValue>{props?.quantity}</ProductDetailValue>
                </ProductDetail>
            </Details>
        </Wrapper>
    );
}

export default OrderItem
