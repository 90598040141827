// Use this log for genertic Concierge fallback
//import logo from '../assets/logos/logo.png'
import wemaLogo from '../assets/logos/wm-logo.png'

export const BRANDS = {
    DEFAULT: {
        logo: wemaLogo,
        primary: '#0061AA'
    }
}
