import React, { useState, createContext, useMemo } from 'react';

const defaultSettings = {};

const AppContext = createContext([{}, () => {}]);

const AppContextProvider = (props) => {
    const [value, setValue] = useState(defaultSettings);
    const appProviderValue = useMemo(() => ({ value, setValue }), [value, setValue]);

    return (
        <AppContext.Provider value={appProviderValue}>
            {props.children}
        </AppContext.Provider>
    );
}

export { AppContext, AppContextProvider }
