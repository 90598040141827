import React from 'react';

import styled from 'styled-components'
import loader from '../assets/loader.svg'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 11px;
    img{
        height: 11px;
        margin: 0px;
        padding: 0px;
    }
`

const Loader = () => {
    return (
        <Wrapper>
            <img src={loader} alt='loading...' />
        </Wrapper>
    )
}
export default Loader
