import {useContext} from "react";
import {AppContext} from '../context/AppContext'
import {isEmpty} from "lodash";
import {useHistory} from "react-router-dom";

const useOrderCheck = () => {
    const { value } = useContext(AppContext);
    const history = useHistory();

    if(isEmpty(value.order)) {
        const orderId = sessionStorage.getItem('orderId');
        const orderKey = sessionStorage.getItem('orderKey');
        const brand = sessionStorage.getItem('brand');
        if(orderId && orderKey){
            history.push(`/order/${orderId}?orderKey=${orderKey}&b=${brand}`);
        } else {
            history.push('/');
        }
    }
};

export default useOrderCheck;