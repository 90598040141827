import React from 'react';

import styled from 'styled-components'
import loader from '../assets/loader2.svg'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 50px;
    img{
        height: 50px;
        margin-top: 10px;
        padding: 0px;
    }
`

const Loader2 = () => {
    return (
        <Wrapper>
            <img src={loader} alt='loading...' />
        </Wrapper>
    )
}
export default Loader2
