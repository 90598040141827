export function formatPhone(phoneNumber) {
    const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g;
    return phoneNumber.trim().replace(phoneRegex, "$2-$3-$4");
}

export function formatHour (hourString) {
    let h = Number(hourString.split(':')[0]);
    let dd = 'AM';

    if (h >= 12) {
        h = h - 12;
        dd = "PM";
    }
    if (h === 0) {
        h = 12;
    }

    return `${h} ${dd}`
}