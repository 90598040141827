import React, { useState } from 'react';
import NoImage from '../assets/NoImage.svg'

const ItemImage = (props) => {
  const [isUndefined, updateIsUndefined] = useState(false);

  const onError = () => {
    updateIsUndefined(true);
  };

  if (isUndefined) {
    return (
      <>
        <img src={NoImage} alt={props.alt}/>
      </>
    );
  }

  return <img src={props.src} alt={props.alt} onError={onError} />;
};

export default ItemImage