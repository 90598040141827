import React, {useContext} from 'react';
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {ThemeContext} from '../context/ThemeContext'

import Loader from './Loader'

const StyledLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 90px;
    height: 45px; 
    font-family: Inter, system-ui; 
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    border-radius: 6px;
    font-size: 16px;
    background-color: ${props => props.bgcolor};
    border: ${props => props.border};
    color: ${props => props.color};
    :disabled,
    [disabled]{
      border: 1px solid #939393;
      background-color:#f5f5f5;
      color: #939393;
    };
    margin-top: auto;
`

const LinkButton = (props) => {
    const {value: themeState} = useContext(ThemeContext);

    return (
        <StyledLink
            to={props.link}
            onClick={props.fn}
            target={props.target}
            color={props.color || 'white'}
            bgcolor={props.bgColor || themeState.primary}
            border={props.border ? props.border : ''}
        >
            {props.isLoading
                ? <Loader />
                : props.text
            }
        </StyledLink>
    )
}

export default LinkButton