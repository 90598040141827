import React from 'react';

export default function ColorBase(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width={150} height={150} {...props}>
      <defs>
        <style dangerouslySetInnerHTML={{__html: `.cls-1{isolation:isolate;}.cls-3{fill:#ececec;mix-blend-mode:multiply;}` }} />
      </defs>
      <g className="cls-1">
        <g id="Layer_1" data-name="Layer 1">
          <circle className="cls-2" cx={75} cy={75} r="59.66" fill={`#${props.hex}`}/>
          <path className="cls-3" d="M134.48,79.65a59.66,59.66,0,0,1-119,0A349.54,349.54,0,0,0,75,84.58,349.54,349.54,0,0,0,134.48,79.65Z" />
        </g>
      </g>
    </svg>
  );
}