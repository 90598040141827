import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
    font-family: Inter, system-ui;
    font-size: 23px;
    font-style: normal; 
    font-weight: bold;
    line-height: 125%;
    text-align: left;
    color: #000000;`

const Title = (props) => {
  return (
    <Wrapper>{props.text}</Wrapper>
  )
}

export default Title
