import React, {useState, createContext, useMemo} from 'react';
const ThemeContext = createContext([{}, () => {}]);

const defaultSettings = {
    header: '#FFFFFF',
    primary: '#6737D4',
    primaryFont: ''
};

const ThemeContextProvider = (props) => {

    const [value, setValue] = useState(defaultSettings);
    const themeProviderValue = useMemo(() => ({ value, setValue }), [value, setValue]);

    return (
        <ThemeContext.Provider value={themeProviderValue}>
            {props.children}
        </ThemeContext.Provider>
    );
}

export { ThemeContext, ThemeContextProvider }