import React, {useContext} from 'react';
import styled from 'styled-components';
import { AppContext } from "../context/AppContext";
import { formatPhone } from './Utils.js';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
    justify-content: space-between
    font-size: 14px;
    font-weight: normal;
    line-height: 125%;
    font-family: Inter, system-ui;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
`
const Box = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
`
const LeftBox = styled.div`
    width: 89%;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
`
const RightBox = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: colomn;
    align-content: center;
`

const Item = styled.div`
    display: flex;
    color: #000000;
    font-size: 14px;
    justify-content: flex-end;
`

const StoreName = styled(Item)`
    font-weight: bold;
    justify-content: flex-start;
    padding-bottom: 5px;
`

const StorePhone = styled.a`
    color: #002A73;
    justify-content: flex-start;
`

/*
const OpenUntil = styled(Item)`
    color: #20967A;
    text-align: right;
    align: center;
`
*/

const StoreAddress = (props) => {
    const { value: appState } = useContext(AppContext);

    return (
    <Wrapper>
        <Box>
            <StoreName>{appState?.order?.storeInfo?.name || ""}</StoreName>
        </Box>
        <Box>
            <LeftBox>
                <div>{appState?.order?.storeInfo?.address1 || ""}</div>
                <div>{appState?.order?.storeInfo?.address2 || ""}</div>
                <div>{appState?.order?.storeInfo?.city || ""},&nbsp;{appState?.order?.storeInfo?.state || ""}&nbsp;{appState?.order?.storeInfo?.postalCode || ""}</div>
                <StorePhone href={`tel:${appState?.order?.storeInfo?.phoneNumber || ''}`}>{formatPhone(appState?.order?.storeInfo?.phoneNumber || '')}</StorePhone>
            </LeftBox>
            <RightBox></RightBox>
        </Box>
    </Wrapper>
    );
}

export default StoreAddress;
