import React from 'react'
import { Transition } from 'react-transition-group';

const duration = 0;
const defaultStyle = {
    'position': 'fixed',
    'bottom': '-150vh',
    'backgroundColor': '#fff',
    'width': '100%',
    'boxShadow': '0 0 4px 0px rgba(0, 0, 0, 0.15)',
    'left': '0',
    'transition': 'all 0.3s ease-out',
    'zIndex': 9999
}

const transitionStyles = {
    entering: { bottom: 0 },
    entered: { bottom: 0 },
    exiting: { bottom: '-150vh' },
    exited: { bottom: '-150vh' }
}

const Modal = (props) => (
    <Transition in={props.in} timeout={duration}>
        {state => (
            <div
                style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}
            >
                { props.children }
            </div>
        )}
    </Transition>
  )

export default Modal