import React from 'react'
import styled from 'styled-components'

import Button from './LinkButton'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 75px;
  padding: 8px;
  
  a {
    flex: 1 1 auto;
    margin: 8px;
    flex: 1;
  }
`

const LinkButtonContainer = (props) => {
    return (
        <Container>
            <Button text={props.secondaryText}
                    color={props.secondaryColor}
                    bgColor={props.secondaryBGColor}
                    fn={props.secondaryAction}
                    border={props.secondaryBorder}
            />
            <Button text={props.primaryText}
                    isLoading={props.isLoadingPrimary}
                    color={props.primaryColor}
                    bgColor={props.primaryBGColor}
                    fn={props.primaryAction}
                    border={props.primaryBorder}
            />
        </Container>
    )
}

export default LinkButtonContainer