import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";

import './i18n';
import './index.css';
import App from './App';
import Bootstrap from "./views/Bootstrap";
import config from './config/vars';

const pjson = require('../package.json');

Sentry.init({
    dsn: config.sentry,
    autoSessionTracking: true,
    release: "curbside-westmarine@" + pjson.version,
    // Ensure our application specifies which environment it is running
    // in when errors/logs are passed to Sentry
    environment: config.environmentType,
});

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<Bootstrap />}>
            <App useSuspense={true} />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
