import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    align-items: center;
    border-radius: 5px;
    border: 1px solid currentColor;
    box-sizing: border-box;
    color:  ${props => props.sel ? "#000000" : "#838895"};
    font-size: ${props => props.sel ?  "bold" : "normal"};
    border-width: ${props => props.sel ? "2px" : "#1px"};
    display: flex;
    flex-direction: column;
    width: 98px;
    height: 78px;
    justify-content: flex-end;
    padding: 0.5rem;
    text-align: center;
        
    svg {
      margin-bottom: 0.35rem;
    }
`

const VehicleType = (props) => {
  return (
    <Wrapper sel={props.selected}>
      <label htmlFor={props.name} onClick={() => props.fn(props.name)}>
          {props.image}
          <div className="label">{props.name}</div>
      </label>
    </Wrapper>
  )
}

export default VehicleType