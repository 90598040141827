//Core
import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.p`
font-size: .9em;
color: var(--grey);
font-weight: 400;
letter-spacing: 0.2px;
padding-bottom: 0;
margin: 0;

@media only screen and (max-width: 320px) {
  font-size: .8em;
}

`

const Description = (props) => {
  return (
    <Wrapper>{props.text}</Wrapper>
  )
}

export default Description
