import React, {useContext} from 'react'
import {AppContext} from "../context/AppContext";
import styled from "styled-components";
import OrderItem from './OrderItem'

const OrderContainer = styled.div`
    flex: 1 1 auto;
    overflow-y: auto;
    max-height: 80vh;
`

const Order = (props) => {
  const { value: appState } = useContext( AppContext );

  return (
    <OrderContainer>
        {appState?.order?.items.map((obj, index) =>
            <OrderItem
                key={index}
                name={obj.name}
                imageUrl={obj.imageUrl}
                quantity={obj.quantity}
                price={obj.price}
            />
        )}
    </OrderContainer>
  );
}

export default Order;
