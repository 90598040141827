import React, {useContext} from 'react';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AppContext } from "../context/AppContext";
import { ModalContext } from "../context/ModalContext";
import { formatPhone, formatHour } from './Utils.js';
import clock from '../assets/clock.svg';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between
    font-size: 14px;
    font-weight: normal;
    line-height: 125%;
    font-family: Inter, system-ui;
    padding: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
`
const Box = styled.div`
    width: 49%;
    align-content: center;
`

const Item = styled.div`
    display: flex;
    color: #000000;
    font-size: 14px;
    justify-content: flex-end;
`

const StoreName = styled(Item)`
    font-weight: bold;
    justify-content: flex-start;
    padding-bottom: 5px;
`

const StorePhone = styled.a`
    color: #002A73;
    justify-content: flex-start;
`

const OpenUntil = styled(Item)`
    color: #20967A;
    text-align: right;
    align: center;
`

const ViewSchedule = styled(Item)`
    color: #002A73;
    line-height: 140%;
    font-size: 12px;
    text-align: right;
    text-decoration: underline;
    margin-top: 5px;
`

const StoreInfo = (props) => {
    const { t } = useTranslation();
    const { value: appState } = useContext(AppContext);
    const { setValue: setModalState } = useContext(ModalContext);

    const handleScheduleClick = () => {
        setModalState(state => ({
            ...state ,
            showOverlay: true,
            showOrderModal: false,
            showScheduleModal: true
        }));
    }

    const getClosingTime = () => {
        const storeHoursArray = appState?.order?.storeInfo?.storeHours || [];
        const today = dayjs();
        if(storeHoursArray.length) {
            let todayObj = find(storeHoursArray, function(dayRow) {
                return dayjs(dayRow.date).day() === today.day();
            })
            if (todayObj) {
                return `${t('storeinfo.text.open.until', { defaultValue: 'Open until'})} ${formatHour(todayObj.close)}`
            } else {
                return `${t('storeinfo.text.open.until', { defaultValue: 'Open until '})} ${formatHour(storeHoursArray[0].close)}`
            }

        } else {
            return null
        }
    }

    return (
    <Wrapper>
        <Box>
            <StoreName>{appState?.order?.storeInfo?.name || ""}</StoreName>
            <StorePhone href={`tel:${appState?.order?.storeInfo?.phoneNumber || ''}`}>{formatPhone(appState?.order?.storeInfo?.phoneNumber || '')}</StorePhone>
        </Box>
        <Box>
            <OpenUntil>
                <img src={clock} alt="" />&nbsp;{ getClosingTime() }
            </OpenUntil>
            <ViewSchedule onClick={handleScheduleClick}>{t('storeinfo.text.storeinfo.link', { defaultValue: 'View store information'})}</ViewSchedule>
        </Box>
    </Wrapper>
    );
}

export default StoreInfo;
