import React from 'react'
import { Transition } from 'react-transition-group';

const duration = 0;
const defaultStyle = {
  'backgroundColor': 'rgba(0,0,0,0)',
  'top': 0,
  'bottom': 0,
  'left': 0,
  'right': 0,
  'position': 'fixed',
  'display': 'none',
  'zIndex': 100000000,
  'opacity': 0.5
}

const transitionStyles = {
  entering: { display: 'block' },
  entered: { display: 'block' },
  exiting: { display: 'none' },
  exited: { display: 'none' }
}

const TapGuard = (props) => {

  return (
      <Transition in={props.in} timeout={duration}>
        {state => (
            <div style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
            >
            </div>
        )}
      </Transition>
  )
}

export default TapGuard