import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'
import Backend from "./Backend";
import LanguageDetector from 'i18next-browser-languagedetector';

// Setup brand if present
const queryParams = new URLSearchParams(window.location.search);
if (queryParams.has('b')){
    sessionStorage.setItem('brand', queryParams.get('b'));
}

// Setup browser detection options
const detectionOptions = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'navigator','sessionStorage', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['sessionStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    cookieOptions: { path: '/', sameSite: 'strict' }
}

// Setup backend loader options
const backendOptions = {
    brand: sessionStorage?.getItem('brand') || '',
}

i18n
    // This is a custom backend built to handle branding override files that are added to the project.  If we need to
    // externalize the language files, more thought would have to be put in as to where/how the branding override occurs.
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //lng: 'cimode',
        lowerCaseLng: true,
        fallbackLng: 'en',
        ns: ['main'],
        defaultNS: 'main',
        detection: detectionOptions,
        backend: backendOptions,
        load: 'languageOnly',
        contextSeparator: ':',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            wait: true,
            useSuspense: true
        },
        keySeparator: false,
        returnEmptyString: false
        //initImmediate: false
        //resources: resources
    }, (err, t) => {
        console.log('i18next has loaded now.');
    });

export default i18n;
