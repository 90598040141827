import React, {useContext} from 'react'
import styled from 'styled-components'
import {AppContext} from "../context/AppContext";
import StoreScheduleItem from './StoreScheduleItem'

const Wrapper = styled.div`
  padding: 16px;
`

const StoreSchedule = (props) => {
  const { value: appState } = useContext( AppContext );

  return (
    <Wrapper>
        {appState?.order?.storeInfo?.storeHours.map((obj, index) =>
            <StoreScheduleItem
                key={index}
                date={obj.date}
                open={obj.open}
                close={obj.close}
            />
        )
        }
    </Wrapper>
  );
}

export default StoreSchedule;
