import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  width: 100%;
  max-width: 180px;
  height: auto;
  margin: 1.5em 0;
  transition: all .5s;
  animation-name: bounceIn;
  animation-duration: .5s; 
  animation-fill-mode: forwards; 

  @media only screen and (max-height: 667px) {
      max-width: 180px;
    }

    @media only screen and (max-width: 320px) {
  margin: .4em 0;
}
`

const HeroImage = (props) => {
  return (
    <Image src={props.src} alt={props.alt}/>
  )
}

export default HeroImage