import React, { useContext } from "react";
import styled from "styled-components";
import { BRANDS } from "../../config/brands";
import QAInfoHeader from "../QAInfoHeader";

//import { AppContext } from '../context/AppContext'
import { ThemeContext } from "../../context/ThemeContext";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 60px;
  min-height: 60px;
  background-color: ${(props) => props.bg};
  box-shadow: 0 2px 4px 0 rgba(209, 209, 209, 0.5);
  font-weight: bold;
  font-size: 20px;
  a {
    display: inherit;
  }
  img {
    max-height: 40px;
    margin: 0px;
    padding: 0px;
  }
`;

const ENVS_TO_SHOW_QA_INFO_HEADER = ["Local", "Dev", "QA"];

const Header = ({environmentType}) => {
  const { value: themeState } = useContext(ThemeContext);
  //const {value: appState} = useContext(AppContext);

  //const brand = appState?.order?.brand || '';
  const brand = sessionStorage.getItem("brand");
  const logo = BRANDS[brand]?.logo || BRANDS["DEFAULT"]?.logo;

  return (
    <>
      {ENVS_TO_SHOW_QA_INFO_HEADER.includes(environmentType) && (
        <QAInfoHeader />
      )}
      <Wrapper bg={themeState?.header}>
        <img src={logo} alt="" />
      </Wrapper>
    </>
  );
};
export default Header;
