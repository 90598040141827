import React from 'react'
import styled from 'styled-components'
import Color from './Color'
import selected from '../../assets/color_selected.svg'

const Wrapper = styled.div`
    flex-direction: row;
    flex-wrap: nowrap;  
    box-sizing: border-box;
    margin-bottom: 24px;
        
    float: left;
    input[type=radio] { display: none; }
    
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 54px;
      border-radius: 15px;
      position: relative;

      margin: 0;
      padding: 2px 10px;
      margin-right: 10px;
  
      font-family: Inter, system-ui;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 125%;
      color: #000000;
  
      img:first-of-type{
        position: absolute;
      }
      img,svg{
        width: 100%;
        height: 100%;
      }
    }
`

const Container = styled.div`
  position: relative;
  height: 50px;
`

const VehicleColor = (props) => {
  return (
    <Wrapper>
      <input
       type="radio"
       name="colors"
       value={props.name}
       defaultChecked={() => console.log(`Selected ${props.name}`)}
      />
      <label htmlFor={props.name} onClick={() => props.fn(props.name)}>
        <Container>
        {props.selected ? <img src={selected} alt=""/> : null}
        <Color hex={props.hex}/>
        </Container>
        {props.name}
      </label>
    </Wrapper>
  )
}

export default VehicleColor